import * as Yup from 'yup';
import {isValidPhoneNumber} from 'libphonenumber-js';
import moment from 'moment';
import i18n from 'components/translate';
//constants
import {REGEX_EMAIL, REGEX_ORG_NAME, REGEX_PHONENUMBER, REGEX_TITLE, REGEX_WEBSITE} from 'core/constants';
import {REPEAT_OPPO_ENDS_TYPE} from 'components/Organization/Opportunities/OpportunityForm/OpportunityMainForm/RepeatOpportunity/RepeatOpportunityModal/formConfiguration';
import {IOrganisationOpportunitySettingsResponse} from '@joc/api-gateway/lib/api-client';

const validatePhoneNumber = (phoneValue: string): boolean => {
  const isHebrew = phoneValue.startsWith('+972');
  return isHebrew ? isValidPhoneNumber(phoneValue, 'IL') : isValidPhoneNumber(phoneValue);
};

const validationT = i18n.getFixedT(null, 'validation');

export const firstNameSchema = Yup.string()
  .required(validationT('firstNameSchema.required'))
  .test({
    name: '',
    message: validationT('firstNameSchema.min'),
    test(value) {
      return !((value || '')?.trim()?.length <= 1);
    },
  })
  .max(200, validationT('firstNameSchema.max'));

export const lastNameSchema = Yup.string()
  .required(validationT('lastNameSchema.required'))
  .test({
    name: '',
    message: validationT('firstNameSchema.min'),
    test(value) {
      return !((value || '')?.trim()?.length <= 1);
    },
  })
  .max(200, validationT('lastNameSchema.max'));

export const gradeSchema = Yup.number()
  .required(validationT('gradeSchema.required'))
  .typeError(validationT('gradeSchema.invalid'))
  .min(1, validationT('gradeSchema.invalid'))
  .max(12, validationT('gradeSchema.invalid'));

export const emailSchema = Yup.string()
  .required(validationT('emailSchema.required'))
  .email(validationT('emailSchema.email'))
  .lowercase(validationT('emailSchema.lowercase'))
  .test('validate email', validationT('emailSchema.invalid'), (email) => {
    return !!email ? REGEX_EMAIL.test(email) : true;
  });

//TODO schema for upload
// export const uploadPhotoSchema = Yup.object().required().test('validate photo', validationT(''), (photo: File) => {
// })

export const phoneNumberSchema = Yup.string()
  .test('validate phone number', validationT('phoneNumberSchema.invalid'), (phone) => {
    return phone ? validatePhoneNumber(phone) : true;
  })
  .required(validationT('phoneNumberSchema.required'));

function emptyStringToNull(value: any, originalValue: any) {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
}

function NullToEmptyString(value: any, originalValue: any) {
  if (originalValue === null) {
    return '';
  }
  return value;
}

export const phoneNotRequiredNumberSchema = Yup.string()
  .nullable(true)
  .transform((_, phone) => (!phone ? undefined : phone))
  .test('validate phone number', validationT('phoneNumberSchema.invalid'), (phone) =>
    !!phone ? validatePhoneNumber(phone) : true
  )
  .max(15);

export const birthDateSchema = Yup.date()
  .nullable(true)
  .typeError(validationT('birthDateSchema.typeError'))
  .transform(function (value, originalValue) {
    if (moment.isDate(value)) {
      return value;
    }
    return moment(originalValue).format('dd.MM.yyyy');
  })
  .max(moment().subtract(5, 'years').toDate(), validationT('birthDateSchema.max'))
  .min('1900-01-01', 'The date provided is too old. Please enter a more recent date')
  .required(validationT('birthDateSchema.required'));

export const birthDatePleaseRequiredSchema = Yup.date()
  .nullable(true)
  .typeError(validationT('birthDateSchema.typeError'))
  .transform(function (value, originalValue) {
    if (moment.isDate(value)) {
      return value;
    }
    return moment(originalValue).format('dd.MM.yyyy');
  })
  .max(moment().subtract(5, 'years').toDate(), validationT('birthDateSchema.max'))
  .min('1900-01-01', 'The date provided is too old. Please enter a more recent date')
  .required(validationT('birthDateSchema.pleaseRequired'));

export const birthNotRequiredDateSchema = Yup.date()
  .nullable(true)
  .typeError(validationT('birthDateSchema.typeError'))
  .transform(function (value, originalValue) {
    if (moment.isDate(value)) {
      return value;
    }

    return !!originalValue ? moment(originalValue).format('DD.MM.YYYY') : null;
  })
  .max(moment().subtract(5, 'years').toDate(), validationT('birthDateSchema.max'))
  .min('1900-01-01', 'The date provided is too old. Please enter a more recent date');

export const opportunityDateSchema = Yup.date()
  .nullable(true)
  .required(validationT('opportunityDateSchema.required'))
  .typeError(validationT('opportunityDateSchema.typeError'));

export const opportunityUpdateStartDateSchema = Yup.date()
  .nullable(true)
  .transform(function (value, originalValue) {
    if (moment.isDate(value)) {
      return value;
    }
    return moment(originalValue).format('dd.MM.yyyy');
  })
  .required(validationT('opportunityDateSchema.required'))
  .typeError(validationT('opportunityDateSchema.typeError'));

export const opportunityStartDateSchema = Yup.date()
  .nullable(true)
  .transform(function (value, originalValue) {
    if (moment.isDate(value)) {
      return value;
    }
    return moment(originalValue).format('DD.MM.YYYY');
  })
  .required(validationT('opportunityDateSchema.required'))
  .typeError(validationT('opportunityDateSchema.typeError'))
  .test('test-past', validationT('opportunityDateSchema.startDate.future'), function (value) {
    const formattedDate = moment(value).format('DD.MM.YYYY');
    return moment(formattedDate, 'DD.MM.YYYY').isSameOrAfter(moment(), 'day');
  });

export const genderSchema = Yup.number()
  .required(validationT('genderSchema.required'))
  .min(1, validationT('genderSchema.pleaseChoose'))
  .max(2, validationT('genderSchema.pleaseChoose'));

export const genderNotRequiredSchema = Yup.number()
  .min(1, validationT('genderSchema.pleaseChoose'))
  .max(2, validationT('genderSchema.pleaseChoose'))
  .nullable(true)
  .transform((value: string, originalvalue: string) => (originalvalue ? Number(originalvalue) : null));

const basicAddressFields = {
  countryName: Yup.string().nullable(true),
  stateName: Yup.string().nullable(true),
  cityName: Yup.string().nullable(true),
  streetName: Yup.string().nullable(true),
  buildingNumber: Yup.string().nullable(true),
  zipCode: Yup.string().nullable(true),
  pobox: Yup.string().nullable(true),
  longitude: Yup.number().nullable(true).typeError(validationT('addressSchema.typeError')),
  latitude: Yup.number().nullable(true).typeError(validationT('addressSchema.typeError')),
};

export const addressSchema = Yup.object()
  .shape(basicAddressFields)
  .nullable(true)
  // checking self-equality works for NaN, transforming it to null
  .transform((value: any, originalvalue: any) => originalvalue || null);

// Necessary for checking addresses in 'About You' and 'Edit account' forms.
export const notRequiredVolunteerAddressSchema = Yup.object()
  .shape({...basicAddressFields, text: Yup.string().nullable(true)})
  .nullable(true)
  .transform((value: any, originalvalue: any) => originalvalue || null)
  .test('autocomplete-validation', validationT('addressSchema.selectFromList'), function (value: any, ctx: any) {
    if (
      !value.text ||
      value.countryName ||
      value.stateName ||
      value.cityName ||
      value.streetName ||
      value.buildingNumber ||
      value.zipCode ||
      value.pobox
    ) {
      return true;
    }

    return ctx.createError({message: validationT('addressSchema.selectFromList')});
  });

export const addressMandatorySchema = Yup.object()
  .required(validationT('addressSchema.required'))
  .shape({
    countryName: Yup.string().nullable(true),
    stateName: Yup.string().nullable(true),
    cityName: Yup.string().nullable(true).required(validationT('addressSchema.cityNameRequired')),
    streetName: Yup.string().nullable(true),
    buildingNumber: Yup.string().nullable(true),
    zipCode: Yup.string().nullable(true),
    pobox: Yup.string().nullable(true),
    longitude: Yup.number().nullable(true).typeError(validationT('addressSchema.typeError')),
    latitude: Yup.number().nullable(true).typeError(validationT('addressSchema.typeError')),
  })
  .nullable(true);

export const orgAddressMandatorySchema = Yup.object()
  .shape(basicAddressFields)
  .nullable(true)
  .test('autocomplete-validation', validationT('addressSchema.selectFromList'), function (value: any, ctx: any) {
    console.log(value);

    if (
      !Object.hasOwn(value, 'text') &&
      (value.countryName ||
        value.stateName ||
        value.cityName ||
        value.streetName ||
        value.buildingNumber ||
        value.zipCode ||
        value.pobox)
    ) {
      if (!value.cityName) {
        return ctx.createError({message: validationT('addressSchema.cityNameRequired')});
      }

      return true;
    }

    if (Object.hasOwn(value, 'text') && value.text?.length) {
      return ctx.createError({message: validationT('addressSchema.selectFromList')});
    }

    return ctx.createError({message: validationT('addressSchema.required')});
  });

export const positionSchema = Yup.number()
  .required(validationT('positionSchema.required'))
  .moreThan(0, validationT('positionSchema.required'));

export const organizationNameShema = Yup.string()
  .min(2, validationT('organizationNameShema.min'))
  .max(100, validationT('organizationNameShema.max'))
  .matches(REGEX_ORG_NAME, validationT('useAlphanumericAndSpecialSymbols'))
  .required(validationT('organizationNameShema.required'));

export const imagePathSchema = Yup.string().required(validationT('imagePathSchema.required'));

export const imageOppoPathSchema = Yup.string();

export const organizationDescriptionSchema = Yup.string().required(
  validationT('organizationDescriptionSchema.required')
);

export const studentIdSchema = Yup.string()
  .required(validationT('studentId.required'))
  .max(100, validationT('studentId.max'))
  .matches(/^[0-9]+$/, validationT('studentId.matches'))
  .nullable();

export const websiteSchema = Yup.string()
  .matches(REGEX_WEBSITE, validationT('websiteSchema.matches'))
  .required(validationT('websiteSchema.required'));

export const passwordSchema = Yup.string()
  .min(6, validationT('passwordSchema.min6'))
  .required(validationT('passwordSchema.required'));

export const confirmPasswordSchema = Yup.string()
  .min(6, validationT('passwordSchema.min6'))
  .required(validationT('passwordSchema.required'))
  .oneOf([Yup.ref('password'), null], validationT('passwordSchema.notEqual'));

export const opportunityNameSchema = Yup.string()
  .transform((value) => (value.trim().length ? value : ''))
  .required(validationT('opportunityNameSchema.required'))
  .min(2, validationT('opportunityNameSchema.min'))
  .max(100, validationT('opportunityNameSchema.max'))
  .matches(REGEX_TITLE, validationT('useAZSymbolsNumbers'));

export const opportunityVolunteersLimitSchema = Yup.number()
  .positive()
  .integer('Enter number greater than 0')
  .min(1, 'Enter number greater than 0')
  .nullable(true)
  .transform((_, val) => (val === Number(val) ? val : null));

export const recipientNameSchema = Yup.string().notRequired();
export const contactDetailsSchema = Yup.string().notRequired();

export const recipientNameRequiredSchema = Yup.string().required(
  validationT('getInTouchValidationSchema.required.name')
);

export const opportunityUrlSchema = Yup.string()
  .required(validationT('opportunityUrlSchema.required'))
  .max(100, validationT('opportunityUrlSchema.max'))
  .nullable(true);

export const opportunityDescriptionSchema = Yup.string()
  .transform((value) =>
    value.replaceAll('&nbsp;', '').replaceAll('<p>', '').replaceAll('</p>', '').trim().length ? value : ''
  )
  .test('required', validationT('opportunityDescriptionSchema.required'), (value) => {
    if (value) {
      const elem = document.createElement('div');
      elem.innerHTML = value;
      return !!(value && elem.innerText.length);
    }
    return false;
  });

export const opportunityApprovalRequired = Yup.boolean()
  .required(validationT('opportunityApprovalRequired.required'))
  .nullable(true);

export const startTimeSchemaRequired = Yup.string()
  .required(validationT('startTimeSchemaRequired.required'))
  .test('test-valid', validationT('startTimeSchemaRequired.typeError'), function (value: string | undefined) {
    return moment(value, 'HH:mm').isValid();
  })
  .test('test', validationT('startTimeSchemaRequired.lessThanEndTime'), function (value: string | undefined) {
    const {startDate, endDate, endTime} = this.parent;
    if (value && moment(endTime, 'HH:mm').isValid() && moment(startDate).isSame(moment(endDate), 'day')) {
      const startTimeTimestamp = moment()
        .set({hour: +value.split(':')[0], minute: +value.split(':')[1]})
        .format('X');
      const endTimeTimestamp = moment()
        .set({hour: +endTime.split(':')[0], minute: +endTime.split(':')[1]})
        .format('X');
      return startTimeTimestamp < endTimeTimestamp;
    }
    return true;
  })
  .test('test-past', validationT('startTimeSchemaRequired.notLessThanRealTime'), function (value: string | undefined) {
    const startDate = this.parent.startDate as string;
    const realDate = moment().subtract(1, 'minute');
    return moment(`${moment(startDate).format('DD.MM.YYYY')} ${value}`, 'DD.MM.YYYY HH:mm').isAfter(realDate);
  });

export const updateStartTimeSchemaRequired = Yup.string()
  .required(validationT('startTimeSchemaRequired.required'))
  .test('test-valid', validationT('startTimeSchemaRequired.typeError'), function (value: string | undefined) {
    return moment(value, 'HH:mm').isValid();
  })
  .test('test', validationT('startTimeSchemaRequired.lessThanEndTime'), function (value: string | undefined) {
    const {startDate, endDate, endTime} = this.parent;

    if (value && moment(endTime, 'HH:mm').isValid() && moment(startDate).isSame(moment(endDate), 'day')) {
      const startTimeTimestamp = moment()
        .set({hour: +value.split(':')[0], minute: +value.split(':')[1]})
        .format('X');
      const endTimeTimestamp = moment()
        .set({hour: +endTime.split(':')[0], minute: +endTime.split(':')[1]})
        .format('X');
      return startTimeTimestamp < endTimeTimestamp;
    }
    return true;
  })
  .test('test-past', validationT('startTimeSchemaRequired.notLessThanRealTime'), function (value: string | undefined) {
    const startDate = this.parent.startDate as string;
    const realDate = moment().subtract(1, 'minute');
    if (this.parent.isPast) return true;
    return moment(`${moment(startDate).format('DD.MM.YYYY')} ${value}`, 'DD.MM.YYYY HH:mm').isAfter(realDate);
  });

export const startTimeSchema = Yup.string()
  .test('test', validationT('startTimeSchemaRequired.lessThanEndTime'), function (value: string | undefined) {
    if (!this.parent.endTime) return true;
    const endTime = this.parent.endTime;
    if (value && endTime) {
      const startTimeTimestamp = moment()
        .set({hour: +value.split(':')[0], minute: +value.split(':')[1]})
        .format('X');
      const endTimeTimestamp = moment()
        .set({hour: +endTime.split(':')[0], minute: +endTime.split(':')[1]})
        .format('X');
      return startTimeTimestamp < endTimeTimestamp;
    }
    return true;
  })
  .nullable(true);

export const endTimeSchemaRequired = Yup.string()
  .required(validationT('endTimeSchemaRequired.required'))
  .test('test-valid', validationT('endTimeSchemaRequired.typeError'), function (value: string | undefined) {
    return moment(value, 'HH:mm').isValid();
  })
  .test('test', validationT('endTimeSchemaRequired.moreThanStartTime'), function (value: string | undefined) {
    const {startDate, endDate, startTime} = this.parent.startTime;
    if (value && moment(startTime, 'HH:mm').isValid() && moment(startDate).isSame(moment(endDate), 'day')) {
      const endTimeTimestamp = moment()
        .set({hour: +value.split(':')[0], minute: +value.split(':')[1]})
        .format('X');
      const startTimeTimestamp = moment()
        .set({hour: +startTime.split(':')[0], minute: +startTime.split(':')[1]})
        .format('X');
      return startTimeTimestamp < endTimeTimestamp;
    }
    return true;
  });

export const totalHoursSchema = Yup.number()
  .min(0.15, validationT('totalHoursSchema.required'))
  .max(100000, validationT('totalHoursSchema.max'))
  .required(validationT('totalHoursSchema.required'));

export const endTimeSchema = Yup.string()
  .test('test', validationT('endTimeSchemaRequired.moreThanStartTime'), function (value: string | undefined) {
    if (!this.parent.startTime) return true;
    const startTime = this.parent.startTime;
    if (value && startTime) {
      const endTimeTimestamp = moment()
        .set({hour: +value.split(':')[0], minute: +value.split(':')[1]})
        .format('X');
      const startTimeTimestamp = moment()
        .set({hour: +startTime.split(':')[0], minute: +startTime.split(':')[1]})
        .format('X');
      return startTimeTimestamp < endTimeTimestamp;
    }
    return true;
  })
  .nullable(true);

export const opportunityTypesSchema = Yup.number()
  .nullable(true)
  .required(validationT('opportunityTypesSchema.required'))
  .typeError(validationT('opportunityTypesSchema.typeError'));

export const opportunityUserSchema = Yup.number()
  .required(validationT('opportunityUserSchema.required'))
  .nullable(true);

export const roleSchema = Yup.number()
  .min(1, validationT('roleSchema.required'))
  .required(validationT('roleSchema.required'));

export const organisationIdSchema = Yup.string().required(validationT('organisationIdSchema.required'));
export const filePathesSchema = Yup.array()
  .max(3, validationT('filePathesSchema.max'))
  .required(validationT('filePathesSchema.max'));

export const filePathRequiredSchema = Yup.array()
  .min(1, validationT('filePathesSchema.required'))
  .max(3, validationT('filePathesSchema.max'))
  .required(validationT('filePathesSchema.required'));

export const recipientEmailSchema = Yup.string()
  .email(validationT('emailSchema.email'))
  .lowercase(validationT('emailSchema.lowercase'));

export const recipientPhoneNumberSchema = Yup.string()
  .matches(REGEX_PHONENUMBER, validationT('phoneNumberSchema.invalid'))
  .when('recipientEmail', {
    is: (recipientEmail: string) => !recipientEmail,
    then: Yup.string().required(validationT('phoneNumberSchema.required')),
  });

export const organizationGeneralTypeSchema = Yup.string()
  .min(1)
  .required(validationT('organisationGeneralType.required'));

export const timeSpentSchema = Yup.number().min(1, validationT('timeSpent.min'));

const manualOppoEmailValidationRequiredSchema = Yup.string()
  .email(validationT('emailSchema.email'))
  .lowercase(validationT('emailSchema.lowercase'))
  .required(validationT('emailSchema.required'));

export const manualOppoEmailValidationSchema = Yup.string().when(['recipientName', 'recipientPhoneNumber'], {
  is: (recipientName: string, recipientPhoneNumber: number) => recipientName && !recipientPhoneNumber,
  then: manualOppoEmailValidationRequiredSchema,
  otherwise: Yup.string().email(validationT('emailSchema.email')).notRequired(),
});

const manualOppoPhoneValidationRequiredSchema = Yup.string().required(validationT('phoneNumberSchema.required'));

export const manualOppoPhoneValidationSchema = Yup.string()
  .matches(REGEX_PHONENUMBER, validationT('phoneNumberSchema.invalid'))
  .when(['recipientName', 'recipientEmail'], {
    is: (recipientName: string, recipientEmail: string) => {
      return recipientName && !recipientEmail;
    },
    then: manualOppoPhoneValidationRequiredSchema,
    otherwise: Yup.string().notRequired(),
  });

export const opportunityManualValidationSchema = Yup.object().shape(
  {
    opportunityName: opportunityNameSchema,
    opportunityDescription: opportunityDescriptionSchema,
    isApprovalRequired: opportunityApprovalRequired,
    startDate: opportunityDateSchema,
    endTime: totalHoursSchema,
    organisationId: organisationIdSchema,
    filePathes: filePathesSchema,
    recipientName: recipientNameSchema,
    contactDetails: contactDetailsSchema,
    recipientEmail: manualOppoEmailValidationSchema,
    recipientPhoneNumber: manualOppoPhoneValidationSchema,
  },
  [
    ['recipientEmail', 'recipientName'],
    ['recipientEmail', 'recipientPhoneNumber'],
    ['recipientPhoneNumber', 'recipientName'],
    ['recipientPhoneNumber', 'recipientEmail'],
  ]
);

export const dynamicManualOppoValidation = (mandatoryFields: IOrganisationOpportunitySettingsResponse) => {
  return Yup.object().shape({
    opportunityName: opportunityNameSchema,
    isApprovalRequired: opportunityApprovalRequired,
    startDate: opportunityDateSchema,
    organisationId: organisationIdSchema,
    ...(mandatoryFields.mandatoryDescription || mandatoryFields.mandatoryReflection
      ? {opportunityDescription: opportunityDescriptionSchema}
      : {}),
    ...(mandatoryFields.mandatoryTag ? {tagId: Yup.string().required(validationT('hashTag.required')).nullable()} : {}),

    ...(mandatoryFields.mandatoryDuration ? {endTime: totalHoursSchema} : {}),

    ...(mandatoryFields.mandatoryPhotos ? {filePathes: filePathRequiredSchema} : {}),

    ...(mandatoryFields.mandatoryRecipientName ? {recipientName: recipientNameRequiredSchema} : {}),
    ...(mandatoryFields.mandatoryRecipientEmail ? {recipientEmail: manualOppoEmailValidationRequiredSchema} : {}),
    ...(mandatoryFields.mandatoryRecipientPhone
      ? {
          recipientPhoneNumber: manualOppoPhoneValidationRequiredSchema.matches(
            REGEX_PHONENUMBER,
            validationT('phoneNumberSchema.invalid')
          ),
        }
      : {}),
  });
};

export const opportunityAdminManualValidationSchema = Yup.object().shape({
  opportunityName: opportunityNameSchema,
  opportunityDescription: opportunityDescriptionSchema,
  startDate: opportunityDateSchema,
  endTime: totalHoursSchema,
  filePathes: filePathesSchema,
});

export const inviteUsersValidationSchema = Yup.object().shape({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  email: emailSchema,
  phoneNumber: phoneNotRequiredNumberSchema,
  roleId: roleSchema,
});
export const inviteUsersValidationAddingSchema = Yup.object().shape({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  email: emailSchema,
  phoneNumber: phoneNotRequiredNumberSchema,
  roleId: roleSchema,
  password: passwordSchema,
});

export const volunteerValidationSchema = Yup.object().shape({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  email: emailSchema,
  phoneNumber: phoneNotRequiredNumberSchema,
  studentId: studentIdSchema.notRequired(),
});

export const volunteerSchemaAdding = Yup.object().shape({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  email: emailSchema,
  phoneNumber: phoneNotRequiredNumberSchema,
  studentId: studentIdSchema.notRequired(),
  password: passwordSchema,
});

export const volunteerSchemaStudentAdding = Yup.object().shape({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  email: emailSchema,
  emailIsExists: Yup.boolean().equals([false], 'Email already exists'),
  gradeId: gradeSchema,
  phoneNumber: phoneNotRequiredNumberSchema,
  studentId: studentIdSchema.notRequired(),
  password: passwordSchema,
});

export const studentValidationSchema = Yup.object().shape({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  email: emailSchema,
  gradeId: gradeSchema,
  phoneNumber: phoneNotRequiredNumberSchema,
  studentId: studentIdSchema.notRequired(),
});

export const oppoFilterValidationSchema = Yup.object().shape({
  startTime: startTimeSchema,
  endTime: endTimeSchema,
});

export const userInfoFormValidationSchema = Yup.object().shape({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  email: emailSchema,
  phoneNumber: phoneNumberSchema,
});

export const orgSignupValidationSchema = Yup.object().shape({
  organizationName: organizationNameShema,
  organisationGeneralType: Yup.string().min(1).required(validationT('organisationGeneralType.required')),
  organizationLogoPath: imagePathSchema,
  organizationDescription: organizationDescriptionSchema,
});

export const aboutYouSignupFormValidationSchema = Yup.object().shape({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  phoneNumber: phoneNumberSchema,
  gender: genderSchema,
  address: addressMandatorySchema,
  birthDate: birthDateSchema,
  email: emailSchema,
});

export const contactOrgSignupFormValidationSchema = Yup.object().shape({
  organizationPhoneNumber: phoneNumberSchema,
  organizationEmail: emailSchema,
  organizationWebSite: websiteSchema,
  address: addressMandatorySchema,
});

export const OrgInfoFormValidationSchema = Yup.object().shape({
  organizationName: organizationNameShema,
  organizationLogoPath: imagePathSchema,
  organizationDescription: organizationDescriptionSchema,
  organizationPhoneNumber: phoneNumberSchema,
  organizationEmail: emailSchema,
  organizationWebSite: websiteSchema,
  address: addressSchema,
});

export const getInTouchValidationSchema = Yup.object().shape({
  email: Yup.string().email(validationT('emailSchema.email')).required(validationT('emailSchema.required')),
  subject: Yup.string().required(validationT('getInTouchValidationSchema.required.subject')),
  name: Yup.string().required(validationT('getInTouchValidationSchema.required.name')),
  message: Yup.string().required(validationT('getInTouchValidationSchema.required.message')),
  country: Yup.string().required(validationT('getInTouchValidationSchema.required.country')),
  captcha: Yup.string().required(validationT('getInTouchValidationSchema.required.captcha')).nullable(),
});

export const loginFormValidationSchema = Yup.object().shape({
  email: emailSchema,
  password: passwordSchema,
});

export const loginFromEmailFormValidationSchema = Yup.object().shape({
  password: passwordSchema,
  confirmPassword: confirmPasswordSchema,
});

export const volunteerInfoFormValidationSchema = Yup.object().shape({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  email: emailSchema,
  phoneNumber: phoneNotRequiredNumberSchema,
  birthDate: birthNotRequiredDateSchema,
  gender: genderNotRequiredSchema,
  address: addressSchema,
});

export const volunteerSignUpFormValidationSchema = Yup.object().shape({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  email: emailSchema,
  phoneNumber: phoneNotRequiredNumberSchema,
  birthDate: birthNotRequiredDateSchema,
  gender: genderNotRequiredSchema,
  address: addressSchema,
  organisationId: organisationIdSchema,
});

export const signUpFormVolValidationSchema = Yup.object().shape({
  email: emailSchema,
});

export const recoverySendEmailFormValidationSchema = Yup.object().shape({
  email: Yup.string().email(validationT('emailSchema.email')).required(validationT('emailSchema.required')),
});

export const recurrenceFormValidationSchema = Yup.object().shape({
  endsType: Yup.string().test('EndsType is valid', 'Pick your type of oppo finishing', (value) => {
    if (value === REPEAT_OPPO_ENDS_TYPE.NONE) return false;
    return !!value;
  }),
  frequency: Yup.string().required('frequency is required'),
  interval: Yup.number()
    .required(validationT('repeated.inputs.intervalRequired'))
    .min(1, validationT('repeated.inputs.min'))
    .max(999, validationT('repeated.inputs.max')),
  endDate: Yup.date()
    .nullable()
    .typeError(validationT('opportunityDateSchema.typeError'))
    .test('endDate is valid', validationT('opportunityDateSchema.required'), (value, context) => {
      if (context.parent.endsType === REPEAT_OPPO_ENDS_TYPE.ON) return moment.isDate(value);
      return true;
    })
    .test('endDate is valid', validationT('opportunityDateSchema.startDate.future'), (value, context) => {
      if (context.parent.endsType === REPEAT_OPPO_ENDS_TYPE.ON) {
        const formattedDate = moment(value).format('DD.MM.YYYY');
        return moment(formattedDate, 'DD.MM.YYYY').isSameOrAfter(moment(), 'day');
      }
      return true;
    }),
  amount: Yup.number()
    .nullable()
    .test('Amount is valid', validationT('repeated.inputs.amountRequired'), (value, context) => {
      if (context.parent.endsType === REPEAT_OPPO_ENDS_TYPE.AFTER) return !!value;
      return true;
    })
    .min(1, validationT('repeated.inputs.min'))
    .max(999, validationT('repeated.inputs.max')),
  daysOfWeek: Yup.array().of(Yup.string()),
});

export const opportunityFormValidationSchemaWithAddress = Yup.object().shape({
  opportunityName: opportunityNameSchema,
  opportunityDescription: opportunityDescriptionSchema,
  isApprovalRequired: opportunityApprovalRequired,
  startTime: startTimeSchemaRequired,
  endTime: endTimeSchemaRequired,
  user: opportunityUserSchema,
  startDate: opportunityStartDateSchema,
  address: addressMandatorySchema,
  volunteersMaxCount: opportunityVolunteersLimitSchema,
});

export const opportunityFormValidationSchemaWithAddressOnUpdate = Yup.object().shape({
  opportunityName: opportunityNameSchema,
  opportunityDescription: opportunityDescriptionSchema,
  isApprovalRequired: opportunityApprovalRequired,
  startTime: updateStartTimeSchemaRequired,
  endTime: endTimeSchemaRequired,
  user: opportunityUserSchema,
  startDate: opportunityUpdateStartDateSchema,
  address: addressMandatorySchema,
  volunteersMaxCount: opportunityVolunteersLimitSchema,
});

export const opportunityFormValidationSchemaWithUrl = Yup.object().shape({
  opportunityName: opportunityNameSchema,
  opportunityDescription: opportunityDescriptionSchema,
  isApprovalRequired: opportunityApprovalRequired,
  startTime: startTimeSchemaRequired,
  endTime: endTimeSchemaRequired,
  user: opportunityUserSchema,
  startDate: opportunityStartDateSchema,
  volunteersMaxCount: opportunityVolunteersLimitSchema,
  opportunityUrl: opportunityUrlSchema.notRequired(),
});

export const opportunityFormValidationSchemaOnUpdate = Yup.object().shape({
  opportunityName: opportunityNameSchema,
  opportunityDescription: opportunityDescriptionSchema,
  isApprovalRequired: opportunityApprovalRequired,
  startTime: updateStartTimeSchemaRequired,
  endTime: endTimeSchemaRequired,
  user: opportunityUserSchema,
  startDate: opportunityUpdateStartDateSchema,
  volunteersMaxCount: opportunityVolunteersLimitSchema,
  opportunityUrl: opportunityUrlSchema.notRequired(),
});

export const hashtagFormValidationSchema = Yup.object().shape({
  name: Yup.string().required(validationT('hashTag.required')).max(100, validationT('hashTag.nameLong')),
});

export const FiltersStartDateSchema = Yup.date()
  .nullable()
  .test({
    name: 'lessThanEndTime',
    message: validationT('startTimeSchemaRequired.lessThanEndTime'),
    test(value) {
      if (
        moment(value).isValid() &&
        moment(this.parent.toDate).isValid() &&
        moment(value, 'DD.MM.YYYY').isSame(moment(this.parent.toDate, 'DD.MM.YYYY'), 'day')
      ) {
        return moment(value, 'HH:mm').isBefore(moment(this.parent.toDate, 'HH:mm'));
      }

      return true;
    },
  })
  .typeError(validationT('opportunityDateSchema.typeError'));

export const FiltersEndDateSchema = Yup.date()
  .nullable()
  .test({
    name: 'moreThenStartDate',
    message: validationT('date.endDate.moreThenStartDate'),
    test(value) {
      if (value) {
        return moment(value, 'DD.MM.YYYY').isSameOrAfter(moment(this.parent.fromDate, 'DD.MM.YYYY'), 'day');
      }

      return true;
    },
  })
  .typeError(validationT('opportunityDateSchema.typeError'));

export const DateRangeFiltersValidationSchema = Yup.object().shape({
  fromDate: FiltersStartDateSchema,
  toDate: FiltersEndDateSchema,
});

export const FiltersStartTimeSchema = Yup.string()
  .test({
    name: 'test-valid',
    message: validationT('startTimeSchemaRequired.typeError'),
    test(value: string | undefined) {
      if (value) {
        return moment(value, 'HH:mm').isValid();
      }

      return true;
    },
  })
  .nullable()
  .test({
    name: 'lessThanEndTime',
    message: validationT('startTimeSchemaRequired.lessThanEndTime'),
    test(value) {
      if (value && moment(this.parent.endTime, 'HH:mm').isValid()) {
        return moment(this.parent.endTime, 'HH:mm').isAfter(moment(value, 'HH:mm'));
      }

      return true;
    },
  });

export const FiltersEndTimeSchema = Yup.string()
  .test({
    name: 'test-valid',
    message: validationT('startTimeSchemaRequired.typeError'),
    test(value: string | undefined) {
      if (value) {
        return moment(value, 'HH:mm').isValid();
      }

      return true;
    },
  })
  .nullable()
  .test({
    name: 'moreThenStartTime',
    message: validationT('endTimeSchemaRequired.moreThanStartTime'),
    test(value) {
      if (value && moment(this.parent.startTime, 'HH:mm').isValid()) {
        return moment(this.parent.startTime, 'HH:mm').isBefore(moment(value, 'HH:mm'));
      }

      return true;
    },
  });

export const TimeRangeFiltersValidationSchema = Yup.object().shape({
  startTime: FiltersStartTimeSchema,
  endTime: FiltersEndTimeSchema,
});
